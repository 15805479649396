import React, { Component } from "react"
import { css } from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { FaBookmark } from "react-icons/fa"
import styled from "styled-components"
import searchIcon from "./../images/icons/search.svg"
import SEO from "../components/seo"
import { TiChevronRight } from "react-icons/ti"

import format from "date-fns/format"

const BlogPreview = ({ blog, index, isFirst }) => {
  const { heroImage, publishDate, description, slug, tags, title } = blog
  const recentArticle = "Recent Article"
  return (
    <Post key={index} className={"postRadius"}>
      <Link
        className={index == 0 ? "first-post-image-link" : "smallBlog"}
        to={`/${tags[0]}/${slug}`}
      >
        <div className={`post ${isFirst && "first"} `}>
          <div
            className={`image ${index == 0 && "imageSpecial-media"}`}
            style={{
              backgroundImage: `url(${
                heroImage && heroImage.fluid ? heroImage.fluid.src : ""
              })`,
              backgroundSize: "cover",
              backgroundPosition: "top left",
              minWidth: "66%",
            }}
          />

          <div className="postDetailsContainer">
            <p className={isFirst ? "dateHead" : "date"}>
              {format(new Date(publishDate), "MMMM do, yyyy")}
            </p>
            <div className="caption">
              <h3>
                <Link
                  className={index == 0 ? "first-post-image-link" : ""}
                  to={`/${tags[0]}/${slug}`}
                >
                  {title}
                </Link>
              </h3>
              <p
                className={`description ${
                  index == 0 && "featurePostText-media"
                }`}
              >
                {description.description.split(" ").slice(0, 40).join(" ")}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </Post>
  )
}

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: this.props.selected,
      posts: props.data.allContentfulBlogPost.edges.map((node) => {
        return {
          ...node.node,
        }
      }),
      filter: "",
      data: props.data.allContentfulBlogPost.edges.map((node) => {
        return {
          ...node.node,
        }
      }),
      type: "news",
    }
  }

  componentDidUpdate = ({ selected }) => {
    if (selected !== this.state.activeItem)
      this.setState({ activeItem: selected })
  }

  handleClickMenu = (index) => async () => {
    switch (index) {
      case 0: {
        await this.setState({
          data: [],
          filter: "",
          type: "news",
          activeItem: 0,
        })
        this.getFilter()
        break
      }
      case 1: {
        await this.setState({
          data: [],
          filter: "",
          type: "merchants",
          activeItem: 1,
        })
        this.getFilter()
        break
      }
      case 2: {
        await this.setState({
          data: [],
          filter: "",
          type: "travel",
          activeItem: 2,
        })
        this.getFilter()
        break
      }
      default: {
        await this.setState({
          data: [],
          filter: "",
          type: "education",
          activeItem: 3,
        })
        this.getFilter()
        break
      }
    }
  }

  onChange = async (event) => {
    await this.setState({ filter: event.target.value })
    this.getFilter()
  }

  sortByDate = () => {
    return this.sort(
      (a, b) => new Date(b.publishDate) - new Date(a.publishDate)
    )
  }

  getFilter = () => {
    const { filter, posts, type } = this.state
    let filteredData = posts
      .filter((post) => {
        const tags = post.tags || []
        return (
          tags.includes(type) &&
          (filter.trim() === "" ||
            tags.includes(filter.trim().toLowerCase()) ||
            post.description.description
              .trim()
              .toLowerCase()
              .includes(filter.trim().toLowerCase()) ||
            post.title
              .trim()
              .toLowerCase()
              .includes(filter.trim().toLowerCase()))
        )
      })
      .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
    this.setState({ data: filteredData })
  }

  renderSearchBox = () => {
    return (
      <SearchContainer>
        <div className="searchBoxContainer">
          <img src={searchIcon} className="searchImage" alt="Travel By Bit" />
          <input
            type="text"
            value={this.state.filter}
            className="search-text-box form-control"
            placeholder="Search"
            onChange={this.onChange}
          />
        </div>
      </SearchContainer>
    )
  }

  render() {
    return (
      <>
        <SEO title="Blog" />
        <Page>
          <React.Fragment>
            <div className="container top-section">
              <ItemNewContainer>
                <ItemNew
                  selected={this.state.type === "news"}
                  onClick={this.handleClickMenu(0)}
                >
                  NEWS
                </ItemNew>
                <ItemNew
                  selected={this.state.type === "merchants"}
                  onClick={this.handleClickMenu(1)}
                >
                  MERCHANTS
                </ItemNew>
                <ItemNew
                  selected={this.state.type === "travel"}
                  onClick={this.handleClickMenu(2)}
                >
                  TRAVEL
                </ItemNew>
                <ItemNew
                  selected={this.state.type === "education"}
                  onClick={this.handleClickMenu(3)}
                >
                  EDUCATION
                </ItemNew>
              </ItemNewContainer>
              {this.renderSearchBox()}
            </div>
          </React.Fragment>
          <div className="container">
            {this.state.data &&
              Array.isArray(this.state.data) &&
              this.state.data.length && (
                <div className="content">
                  <div className="post-container-new">
                    {this.state.data[0] && (
                      <BlogPreview
                        blog={this.state.data[0]}
                        key={"vfdvdgvd"}
                        isFirst
                        index={0}
                      />
                    )}
                  </div>
                  <div className="recentArticle">Recent articles</div>
                  <div className="post-container">
                    {this.state.data.map((blog, i) => {
                      if (i >= 1) {
                        return <BlogPreview blog={blog} key={i} index={i} />
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              )}
          </div>
        </Page>
      </>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  {
    allContentfulBlogPost(sort: { order: DESC, fields: [publishDate] }) {
      edges {
        node {
          id
          slug
          title
          tags
          publishDate
          description {
            description
          }
          heroImage {
            fluid(maxWidth: 600, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;
  background-color: #f9f9f9;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  .backNone {
    background-color: transparent !important;
  }

  .marBottom {
    margin-bottom: 2rem !important;
  }

  .marleftPost {
    margin-left: 1rem !important;
  }

  .searchBoxContainer {
    position: relative;
    width: 100%;
  }

  .searchImage {
    position: absolute;
    top: 9px;
  }

  .searchbox {
    width: 100%;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto 0 auto !important;
    padding-top: 35px;
  }

  .search-text-box {
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    border-radius: 0px !important;
    border-color: rgba(0, 0, 0, 0.12);
    padding-left: 27px !important;
  }

  .content {
    width: 100%;
    border-radius: 5px;
    margin: 0rem 0 0rem 0;
    padding: 0rem 0 2rem 0;

    h2 {
      text-align: center;
    }

    .line {
      height: 1px;
      width: 100%;
      background: rgba(3, 3, 3, 0.2);
      margin-bottom: 2rem;
    }
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    align-items: center;
    width: 100%;

    input,
    select {
      font-size: 16px;
      padding: 10px;
      font-family: ProximaNova;
      border-radius: 4px;
      width: 100%;
      background-color: #f9f9f9;
    }
  }

  .recentArticle {
    font-size: 22px;
    line-height: 1.18;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .post-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    @media only screen and (max-width: 769px) {
      flex-direction: column;
      align-items: center;
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }

  .post-container-new {
    margin-bottom: 2rem;
    display: grid;

    @media only screen and (max-width: 759px) {
      .post {
        flex-direction: column;
        align-items: center;
      }
    }

    .imageSpecial {
      min-height: 415px !important;
    }
  }
`

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1170px;
`

const Post = styled.div`
  display: flex;
  flex-direction: column;
  color: #2d3e52;
  background-color: #fff;
  overflow: hidden !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06);
  transition: box-shadow 500ms;

  &:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  }

  @media only screen and (max-width: 769px) {
    min-height: 100%;
    .postDetailsContainer {
      padding: 1rem !important;
    }
    .first {
      display: block;
    }
  }
  .first-post-image-link {
    text-decoration: none !important;
  }

  @media only screen and (min-width: 769px) {
    .first-post-image-link {
      line-height: 1.2 !important;
      font-size: 32px !important;
      font-family: ProximaNova;
      color: rgba(0, 0, 0, 0.87);
      text-decoration: none !important;
      min-height: 415px;
    }
    .imageSpecial-media {
      min-height: 415px !important;
    }
    .featurePostText-media {
      font-size: 18px;
      line-height: 1.4 !important;
      font-family: ProximaNova;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .post {
    width: 100%;
  }

  .first {
    display: flex;
  }

  .image {
    width: 100%;
    height: 16rem;
    // border-radius: 4px;
  }

  .postDetailsContainer {
    background-color: #fff;
    padding: 1rem 1.5rem;
  }
  .smallBlog {
    text-decoration: none !important;
  }

  .date {
    line-height: 1.13;
    color: rgba(0, 0, 0, 0.38);
    font-weight: normal;
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 4px;
    font-family: ProximaNova;
  }

  .caption {
    a {
      text-decoration: none;
      font-family: ProximaNova;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.18;
      color: rgba(0, 0, 0, 0.87);
    }
    h3 {
      margin-bottom: 8px;
    }
  }

  .postRadius {
    overflow: hidden;
    border-radius: 10px;
  }

  .description {
    line-height: 1.38 !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: normal;
  }

  .dateHead {
    color: rgba(0, 0, 0, 0.38);
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 12px;
    font-family: ProximaNova;
    padding-top: 20px;
  }

  .read-more {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    a {
      text-decoration: none;
      font-weight: 600;
      color: #2d3e52;
    }

    &:hover {
      .icon {
        transition: all 0.2s ease-in-out;
        transform: translateX(4px);
      }
    }
  }

  .tags {
    margin-top: auto;
    display: flex;
    align-items: center;

    .tag-icon {
      margin-right: 0.7rem;
      color: #fd8e0f;
    }
  }

  @media only screen and (max-width: 759px) {
    width: 100%;
  }
`

const ItemNew = styled.button`
  width: auto;
  height: 40px;
  border-radius: 24px;
  padding: 0 20px;
  border: none;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 43px;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    padding: 0 7px !important;
    font-size: 14px !important;
  }
  ${(props) =>
    props.selected &&
    css`
      color: #ffffff;
      background-color: #ef6c00;
    `}
`

const ItemNewContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 80%;
  @media only screen and (max-width: 500px) {
    width: 100% !important;
  }
`
const SearchContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 20%;
  @media only screen and (max-width: 500px) {
    width: 100% !important;
  }
`
